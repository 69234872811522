<template>
    <div class="tabbar">
        <div
        :class="{'tabbar_item': true, 'tabbar_item_active': $route.path === item.path}"
        v-for="(item, index) in tabbarList" :key="item.icon"  @click="switchToTab(item.path)">
            <div class="iconfont">
                {{item.icon}}
            </div>
            <div class="tabbar_title">
                {{item.text}}
            </div>
            <span class="car-num" v-if="index==2 && userId && carNumNew > 0">{{carNumNew}}</span>
        </div>
  </div>
</template>
<script>
export default {
  name: 'Tabbar',
  props: ['carNumNew'],
  data () {
    return {
      tabbarList: [
        { icon: '\ue695', text: '首页', path: '/' },
        { icon: '\ue62f', text: '分类', path: '/category' },
        { icon: '\ue682', text: '购物车', path: '/shopcar' },
        { icon: '\ue78b', text: '我的', path: '/my' }
      ],
      userId: ''
    }
  },
  methods: {
    switchToTab (path) {
      this.$router.push(path)
    }
  },
  created () {
    this.userId = localStorage.userId
  }
}
</script>
<style lang="scss" scoped>
.tabbar{
    display:flex;
    width: 100%;
    height:0.52rem;
    background:#fff;
    padding: 0 0.18rem;
    box-sizing: border-box;
   border-top: 0.01rem solid #f1f1f1;
   position:fixed;
   left: 0;
   bottom:0;
   color:#333;
.tabbar_item{
  flex: 1;
  text-align: center;
  position: relative;
  .iconfont{
    font-size: .20rem;
    margin: 0.05rem 0 0.02rem
  }
  .tabbar_title{
    font-size: 0.15rem;
  }
  }
  .tabbar_item_active{
    color:#2ac560
  }
  .car-num{
    min-width:.2rem;
    min-height:.2rem;
    padding: 0;
    border-radius: 50%;
    background:red;
    position: absolute;
    right: .15rem;
    top: 0;
    font-size: .12rem;
    display: flex;
    align-items:center;
    justify-content: center;
    color:#fff;
  }
}
</style>
