<template>
    <div class="container">
      <div class="swiper">
          <swiper class="mySwiper" :pagination="true">
            <swiper-slide v-for="item in goodsModel.album" :key="item">
              <img v-lazy="item.photoUrl" />
            </swiper-slide>
          </swiper>
      </div>
      <div class="goodsinfo">
          <h3>{{goodsModel.goodsName}}</h3>
          <div class="price-wrap">
              <span class="price-num">￥{{goodsModel.shopPrice}}</span>
              <span class="specs">/{{goodsModel.unit}}</span>
          </div>
          <div class="goods-tips" v-if="goodsModel.spec">
              <text>规格：{{goodsModel.spec}}</text>
          </div>
          <div class="goods-tips">
              <text>库存：{{goodsModel.stock}}</text>
          </div>
          <div class="goods-tips">
              <text>已售：{{goodsModel.sales}}</text>
          </div>
      </div>
      <div class="goods-detail">
          <h3>商品详情</h3>
          <div class="detail-cnt" v-for="item in goodsAppContentList" :key="item">
            <img :src="item.fileUrl" />
            <text
              >{{item.content}}</text
            >
          </div>
      </div>
    </div>
    <div class="fix-bottom">
        <div class="fix-bottom-l">
            <div class="fix-list"  @click="toShopCar">
              <img src="../../images/icon-cart.png"/>
              <span>购物车</span>
            </div>
            <div class="fix-list collect" @click="saveCollect" v-if="goodsModel.isCollect!=1">
              <span class="iconfont icon-jushoucang"></span>
              <span>收藏</span>
            </div>
            <div class="fix-list collect collected" @click="saveCollect" v-else>
              <span class="iconfont icon-jushoucanggift"></span>
              <span>已收藏</span>
            </div>
        </div>
        <div class="addcar-btn" @click="addCar(goodsModel)">加入购物车</div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
import SwiperCore, {
  Pagination
} from 'swiper/core'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.min.css'
SwiperCore.use([Pagination])
export default {
  components: { Swiper, SwiperSlide },
  name: 'GoodsDetail',
  data () {
    return {
      goodsId: '',
      shopId: '',
      goodsModel: {},
      goodsAppContentList: [],
      userId: ''
    }
  },
  methods: {
    async getDetail () {
      const url = 'v1/goods/user/detail.json'
      const param = {
        shopId: this.shopId,
        id: this.goodsId,
        userId: localStorage.userId || ''
      }
      ajaxParam(param)
      this.$toast.loading({
        message: '加载中..',
        forbidClick: true,
        duration: 0
      })
      const res = await post(url, qs.stringify(param))
      this.$toast.clear()
      this.goodsModel = res.data.entity
      this.goodsAppContentList = res.data.goodsAppContentList
    },
    // 添加商品到购物车
    async addCar (model) {
      if (!this.userId) {
        this.$router.push('/login')
        return
      }
      var url = 'v1/cart/saveCart.json'
      var param = {
        shopId: model.shopId,
        userId: localStorage.userId,
        goodsId: model.id,
        number: 1,
        cartId: ''
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      localStorage.carNumNew = res.data.cartNum
      this.$toast(res.data.msg)
    },
    // 收藏 取消收藏
    async saveCollect () {
      if (!this.userId) {
        this.$router.push('/login')
        return
      }
      var url = 'v1/goodsCollect/saveCollect.json'
      var param = {
        shopId: this.shopId,
        userId: localStorage.userId,
        goodsId: this.goodsId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      if (this.goodsModel.isCollect === 1) {
        this.goodsModel.isCollect = 0
        this.$toast('已取消收藏')
      } else {
        this.goodsModel.isCollect = 1
        this.$toast('收藏成功')
      }
    },
    // 去购物车
    toShopCar () {
      if (!this.userId) {
        this.$router.push('/login')
        return
      }
      this.$router.push('/shopcar')
    }
  },
  created () {
    this.goodsId = this.$route.query.id
    this.shopId = this.$route.query.shopId
    this.userId = localStorage.userId || ''
    this.getDetail()
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.container{padding-bottom: .5rem;}
.swiper img{  width: 100%; max-height: 3.5rem;}
.goodsinfo{
    padding: .1rem; background:#fff;
    h3{padding-bottom: .08rem; font-size: .16rem;}
    .goods-tips{
        line-height: .2rem;
        font-size: .13rem;
        color: #666;
    }
}
.price-wrap{
  padding: 0 0 .1rem;
  font-size: .13rem;
  .price-num{color:#ff371e}
  .specs{color:#666}
}
.goods-detail{
    background:#fff;
    margin-top: .1rem;
    padding: .1rem .1rem .6rem;
    h3{ margin-bottom: .1rem; font-size: .15rem;}
    .detail-cnt img{
        width: 100%
    }
}
.fix-bottom{
    position: fixed;
    left:0; right:0; bottom:0;
    height: .4rem;
    background:#fff;
    border-top: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fix-bottom-l{
        // display: flex;
        // flex-direction: column;
        // padding: 0 .1rem;
        // align-items: center;
        // border-right: 1px solid #f3f3f3;
        display:flex;
        .fix-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: .1rem;
          img{
            width: .2rem;
            height: .18rem;
            margin-bottom: .02rem;
          }
          span{font-size: .12rem;}
          span.iconfont{
            margin-bottom:.003rem;
            font-size: .17rem;
            color:#999
          }
        }
        .collect{
          width: .5rem
        }
        .collected span.iconfont{
          color:$green-font
        }
    }
    .addcar-btn{
        height:100%;
        background:$green-font;
        padding: 0 .1rem;
        color:#fff;
        line-height: .4rem;
    }
}
</style>
