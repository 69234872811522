<template>
  <div class="top">
    <Search @click="$router.push('/search')" :isShowBtn="false"/>
    <ul v-show="isTabFixed" class="tabs-title fixedTab">
        <li :class="{'active': currentIndex == index}" @click="changeItem(item, index)" v-for="(item, index) in categoryList" :key="item.id">{{item.name}}</li>
    </ul>
  </div>
  <div class="container">
    <!--轮播-->
    <div class="swiper">
      <swiper class="mySwiper">
        <swiper-slide v-for="item in bannerList" :key="item.id">
          <img :src="item.fileUrl" />
        </swiper-slide>
      </swiper>
    </div>
    <!--公告-->
    <div class="notice-wrap">
      <img src="../../images/index-news.png"/>
      <van-swipe class="swiper2" :autoplay="3000" vertical="false" :show-indicators="false">
        <van-swipe-item @click="$router.push('/newsDetail/'+item.id)" v-for="item in noticeList" :key="item.id">{{item.title}}</van-swipe-item>
      </van-swipe>
      <span @click="$router.push('/newslist')">更多<i class="iconfont icon-jinrujiantouxiao"></i></span>
    </div>
    <!--分类导航-->
    <ul class="nav">
      <li @click="$router.push('/category')">
        <img src="../../images/tab_01.png"/>
        <span>分类</span>
      </li>
      <li @click="$router.push('/home')">
        <img src="../../images/tab_02.png"/>
        <span>社区拼团</span>
      </li>
    </ul>
    <!--拼团-->
    <!-- <div class="group-buy">
        <div class="group-title">
            <img src="../../images/ptg-logo.png"/>
            <a href="#">更多</a>
        </div>
    </div> -->
    <!--商品列表-->
    <div class="wrap">
      <ul class="tabs-title" ref="tabsTitle" id="tabs-title">
          <li :class="{'active': currentIndex == index}" @click="changeItem(item, index)" v-for="(item, index) in categoryList" :key="item.id">{{item.name}}</li>
      </ul>
      <div class="tabs-cnt">
          <ul class="van-clearfix">
            <van-list
              v-model:loading="loading"
              :finished="finished"
              :immediate-check = "false"
              finished-text="没有更多了"
              @load="pageLoad"
            >
              <GoodsItem @click="goGoodsDetail(item)" @addCar="addCar" v-for="(item,index) in dataList" :key="item.id" :goodsItem="item" :userId="userId" :index="index"/>
            </van-list>
          </ul>
          <Tabbar :carNumNew="carNumNew"/>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { onActivated, getCurrentInstance } from 'vue'
import 'swiper/swiper.scss'
import qs from 'qs'
import { post, ajaxParam, config } from '../../units/request.js'
import Tabbar from '../home/Tabbar.vue'
import GoodsItem from '../../components/GoodsItem.vue'
import Search from '../../components/Search.vue'
export default {
  components: { Swiper, SwiperSlide, Tabbar, GoodsItem, Search },
  name: 'HomeNew',
  data () {
    return {
      bannerList: [],
      noticeList: [],
      dataList: [],
      cat1Id: '',
      categoryList: [],
      currentIndex: 0,
      finished: false,
      loading: false,
      pageNum: 1,
      pageSize: 15,
      isLastPage: 0,
      carNumNew: 0,
      isTabFixed: false
    }
  },
  methods: {
    // 获取banner和notice数据
    async getIndexInfo () {
      const url = 'wx/v1/shop/xfshlmIndex.json'
      const param = {
        cityId: config.cityId,
        userId: this.userId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.bannerList = res.data.bannerList
      this.noticeList = res.data.noticeList
      this.carNumNew = res.data.cartNum
      localStorage.carNumNew = this.carNumNew
    },
    // 进商品详情
    goGoodsDetail (item) {
      this.$router.push({
        path: '/goodsdetail',
        query: {
          shopId: item.shopId,
          id: item.id
        }
      })
    },
    // 获取全部商品
    async getAllGoodsList () {
      const url = 'v1/goods/user/cityShopGoods.json'
      const param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        cityId: config.cityId,
        loadCatFlag: 1, // 是否加载分类
        isFirstCat1Flag: 1,
        cat1Id: this.cat1Id,
        userId: this.userId,
        addUserId: this.userId
      }
      ajaxParam(param)
      this.$toast.loading({
        message: '加载中..',
        forbidClick: true,
        duration: 0
      })
      const res = await post(url, qs.stringify(param))
      this.$toast.clear()
      this.categoryList = res.data.categoryList
      // this.dataList = res.data.list
      this.dataList.push(...res.data.list)
      this.isLastPage = res.data.isLastPage
      if (this.isLastPage === 1) {
        this.finished = true
      }
      // 获取完数据把Loading状态改成false
      this.loading = false
    },
    changeItem (item, index) {
      this.currentIndex = index
      this.cat1Id = item.id ? item.id : ''
      this.pageNum = 1
      this.dataList = []
      this.finished = false
      this.loading = false
      this.getAllGoodsList()
      // this.scrollPrev = this.scroll
      // setTimeout(() => {
      //   window.scrollTo(0, this.scrollPrev)
      // }, 500)
    },
    // 分页
    pageLoad () {
      this.loading = true
      this.pageNum++
      this.getAllGoodsList()
    },
    // 添加商品到购物车
    async addCar (model, index) {
      let cartGoodsAmount = 0
      var url = 'v1/cart/saveCart.json'
      var param = {
        shopId: model.shopId,
        userId: this.userId,
        goodsId: model.id,
        number: 1,
        cartId: ''
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      localStorage.carNumNew = res.data.cartNum
      this.carNumNew = res.data.cartNum
      // this.$store.dispatch('changeCarNum', res.data.cartNum)
      res.data.list.forEach(item => {
        item.goodsList.forEach(itemSub => {
          if (itemSub.goodsId === model.id) {
            cartGoodsAmount = itemSub.goodsNum ? itemSub.goodsNum : item.cartGoodsAmount ? item.cartGoodsAmount : 0
          }
        })
      })
      this.dataList[index].cartGoodsAmount = cartGoodsAmount
      this.$toast(res.data.msg)
    },
    // 获取滚动条高度 比tab距顶部比较判断是否显示顶部Tab
    handelScroll () {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop
      if (this.scroll > this.tabScrollHeight * 2) {
        this.isTabFixed = true
      } else {
        this.isTabFixed = false
      }
    },
    changeData () {
      this.pageNum = 1
      this.dataList = []
      this.finished = false
      this.loading = false
      this.getAllGoodsList()
    }
  },
  created () {
    this.userId = localStorage.userId || ''
    this.getAllGoodsList()
    this.getIndexInfo()
    this.carNumNew = localStorage.carNumNew
  },
  mounted () {
    window.addEventListener('scroll', this.handelScroll)
    this.tabScrollHeight = this.$refs.tabsTitle.getBoundingClientRect().top.toFixed(2)
    this.carNumNew = localStorage.carNumNew
    this.userId = localStorage.userId || ''
  },
  watch: {
    // carNumNew: 'changeData'
  },
  setup () {
    const { proxy } = getCurrentInstance()
    onActivated(() => {
      window.addEventListener('scroll', proxy.handelScroll)
      proxy.tabScrollHeight = proxy.$refs.tabsTitle.getBoundingClientRect().top.toFixed(2)
      proxy.carNumNew = localStorage.carNumNew
      proxy.userId = localStorage.userId || ''
      // proxy.getAllGoodsList()
      // proxy.dataList.forEach((item, index) => {
      //   if (index === 2) {
      //     item.cartGoodsAmount = 99
      //   }
      // })
    })
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
.top{position: fixed; height:.4rem; top:0; right:0; left:0; z-index: 99;}
.fixedTab{position: fixed; top:0; right:0; left:0; z-index:100}
.container{padding-top: .4rem; padding-bottom: .5rem;}
.swiper{margin:.1rem; border-radius: .1rem;}
.swiper swiper-slide{border-radius: .1rem;}
.swiper img{width:100%; height:1.5rem; border-radius: .1rem;}
.notice-wrap{
  background:#fff;
  display: flex;
  align-items: center;
  padding: .1rem;
  img{
    width: .5rem;
    height: .1rem;
    margin-right: .1rem;
  }
  .swiper2{
    flex:1;
    display: flex;
    flex-direction: column;
    height: .16rem;
    .swiper-container {
      margin-left: 0
    }
    .van-swipe{
      width: 100%;
      height: 100%;
    }
    .van-swipe-item{
      font-size: .13rem;
      line-height: .18rem;
    }
  }
    span{
      color:$green-font;
      font-size: .12rem;
      display: flex;
      align-items: center;
      i{
        color:$green-font;
        font-size: .09rem;
      }
    }
}
.nav{
  display: flex;
  justify-content: space-around;
  padding: .12rem .1rem .1rem;
  background:#fff;
  border-bottom: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
  margin-bottom: .1rem;
  li{
    display:flex;
    flex-direction: column;
    align-items: center;
    img{
      width: .44rem;
      height: .44rem;
      margin-bottom: .08rem;
    }
  }
  }
.group-buy{background:#fff; margin-bottom: .1rem;}
.group-title{
  padding: .1rem 0;
  position: relative;
  img{
    display: block;
    width: 1.19rem;
    height: .14rem;
    margin: 0 auto
  }
  a{
    position: absolute;
    right: .1rem;
    top: .1rem;
    font-size: .12rem;
    color: #999
  }
}
.wrap{padding-bottom: .1rem;}
.tabs-title{display: flex; padding: .1rem; background:#fff}
.tabs-title li{padding:.03rem .1rem;}
.tabs-title li.active{background:$green-font; border-radius: .1rem; color:#fff}
.tabs-cnt .van-list{display: flex; flex-wrap: wrap;}
.tabs-cnt li{width: 47%; margin:.1rem 0 0 2%; background:#fff; border-radius: .05rem; position:relative}
.goods-img{width: 100%; height:1.25rem; border-radius: .05rem .05rem 0 0;}
.tabs-cnt h3{padding:.05rem .1rem; font-size: .16rem;}
.price-wrap{
  padding: 0 .1rem .1rem;
  font-size: .13rem;
  .price-num{color:$red-font}
  .specs{color:#666}
}
.car-icon{width:.24rem; height: .24rem; position:absolute; right:.1rem; bottom:.1rem}

</style>
